import { createContextualCan } from '@casl/react'
import { isEqual } from 'lodash'
import { createContext, useContext, useEffect } from 'react'
import { useSession } from '../api/auth/use-session'
import { buildAbility } from './ability'
import type { AppAbility } from './types'

const cachedPermissions = (() => {
  if(typeof window === 'undefined') {
    return []
  }
  try {
    return JSON.parse(window.localStorage.getItem('permissions') || '[]')
  } catch (e) {
    return []
  }
})()

const ability = buildAbility(cachedPermissions)

export const AbilityContext = createContext<AppAbility>(ability)
export const Can = createContextualCan(AbilityContext.Consumer)

export const AbilityProvider = ({ children }: { children: React.ReactNode }) => {
  const { status, permissions } = useSession()
  
  useEffect(() => {
    if(status === 'loading') {
      return
    }

    if(!isEqual(permissions, ability?.rules)) {
      ability.update(permissions || [])
      window.localStorage.setItem('permissions', JSON.stringify(permissions || []))
    }
  }, [permissions, status])


  if(status === 'unauthenticated') {
    return <>No permissions</>
  }

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
}

export const usePermissions = () => {
  const ability = useContext(AbilityContext)
  ability.can = ability.can.bind(ability)
  ability.cannot = ability.cannot.bind(ability)
  ability.relevantRuleFor = ability.relevantRuleFor.bind(ability)

  return ability
}