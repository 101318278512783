///////////////////////////////////////////////////////////////////////////////////////////////////
//                                                                                               //
//                             KEEP IN SYNC WITH                                                 //
//                                                                                               //
//              github:apollo_server -> src/auth/authorization/constants.ts                      //
//                                                                                               //
///////////////////////////////////////////////////////////////////////////////////////////////////
export const GUEST_PERMISSIONS = []

export const ROLES = {
  // Admin side
  ADMIN: 'admin',
  SUPER_ADMIN: 'super_admin',

  // Customer's side
  CUSTOMER_OWNER: 'customer_owner',
  CUSTOMER_ADMIN: 'customer_admin',
  CUSTOMER_POWER_USER: 'customer_power_user',
  CUSTOMER_VIEWER: 'customer_viewer',
  CUSTOMER_CUSTOM: 'customer_custom',

  // Custom
  CUSTOM: 'custom',
} as const

export const ROLES_LIST = [...Object.values(ROLES)] as const
export const CUSTOMER_ROLES_WITHOUT_OWNER = [ROLES.CUSTOMER_ADMIN, ROLES.CUSTOMER_POWER_USER, ROLES.CUSTOMER_VIEWER, ROLES.CUSTOMER_CUSTOM] as const
export const CUSTOMER_ROLES = [...CUSTOMER_ROLES_WITHOUT_OWNER, ROLES.CUSTOMER_OWNER] as const
export const ADMIN_ROLES = [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.CUSTOM] as const
export const CUSTOM_ROLES = [ROLES.CUSTOMER_CUSTOM, ROLES.CUSTOM] as const

//// This part doesn't have to be synced with the server
export const roleNamesMap: Record<typeof ROLES[keyof typeof ROLES], string> = {
  admin: 'Admin',
  super_admin: 'Super Admin',
  customer_owner: 'Customer Owner',
  customer_admin: 'Customer Admin',
  customer_power_user: 'Customer Power User',
  customer_viewer: 'Customer Viewer',
  customer_custom: 'Customer Custom',
  custom: 'Admin Custom',
}

export const WITHOUT_CUSTOMER_ID = ['document_types'] as const