import type { ReactNode } from 'react'
import Iconify from '../../components/Iconify'
import { User } from '../../data'
import { ADMIN_ROLES, CUSTOMER_ROLES } from '../../lib/authorization/constants'

const getIcon = (name: string) => <Iconify icon={name} width={22} height={22} />

type NavItem = {
  title: string
  path: string
  icon: ReactNode
  info?: string
  children?: NavItem[]
}

const documents = {
  title: 'AID Search',
  path: '/dashboard/documents',
  icon: getIcon('ion:documents'),
}

const document_types = {
  title: 'Document Types',
  path: '/dashboard/document-types',
  icon: getIcon('gridicons:custom-post-type'),
}

const customers = {
  title: 'Customers',
  path: '/dashboard/customers',
  icon: getIcon('mdi:domain'),
}

const members = {
  title: 'Members',
  path: '/dashboard/members',
  icon: getIcon('gridicons:multiple-users'),
}

const groups = {
  title: 'Companies',
  path: '/dashboard/groups',
  icon: getIcon('bi:boxes'),
}

const document_groups = {
  title: 'Document Groups',
  path: '/dashboard/document-groups',
  icon: getIcon('formkit:group'),
}

const dashboard = {
  title: 'Dashboard (Beta)',
  path: '/dashboard',
  icon: getIcon('wpf:statistics'),
}

const logs = {
  title: 'Audit Log',
  path: '/dashboard/logs',
  icon: getIcon('carbon:document'),
}

const navConfig = (role?: User['role']) => {
  const nav: NavItem[] = []
  if(CUSTOMER_ROLES.includes(role as any)) {
    nav.push(dashboard)
    nav.push(documents)
    nav.push(members)
    nav.push(groups)
    nav.push(document_groups)
    nav.push(logs)
  }

  if(ADMIN_ROLES.includes(role as any)) {
    nav.push(document_types)
    nav.push(customers)
    nav.push(members)
  }

  return nav
}

export default navConfig
