import Cookies from 'universal-cookie'
import { enhancedFetch } from '../lib/api/enhanced-fetch'
import { request, ResponseBody } from './request'
import { getValue } from './utils'

const cookies = new Cookies()

type LoginResponse = ResponseBody & {
  token?: string
  expiresAt?: string
  user?: string
  success?: boolean
}

export type LoginParams = {
  email: string
  password: string
}

export type OtpParam = {
  email: string
  password: string
  otp?: string
}

export const login = async ({ email, password }: LoginParams) => {
  const results = await request<LoginParams, LoginResponse>({
    method: 'post',
    path: 'login',
    data: {
      email,
      password,
    },
  })

  if (getValue(results) != null) {
    const { success, details, otp } = results

    return {
      success,
      details,
      otp,
    }
  }
  return {
    success: false,
    details: 'Unknown Error',
  }
}

export const verifyOtp = async ({ email, password, otp }: OtpParam) => {
  const results = await request<OtpParam, LoginResponse>({
    method: 'post',
    path: 'verify/otp',
    data: {
      email,
      password,
      otp
    },
  })

  if (getValue(results) != null) {
    const { success, user, details, token, expiresAt } = results
    const expires = new Date(expiresAt || new Date().getTime() + 60 * 30 * 1000)
    if (success) {
      refreshToken(user, token, expires)
    }

    return {
      success,
      details,
    }
  }
  return {
    success: false,
    details: 'Unknown Error',
  }
}

/**
 * @deprecated Use auth context instead
 */
export const refreshToken = (newUser?: string, newToken?: string, tokenExpiresAt?: Date) => {

  const current = new Date().getTime()
  const expiresAt = tokenExpiresAt || new Date(current + 60 * 30 * 1000)
  if(newUser)  {
    cookies.set('user', newUser, { path: '/', expires: expiresAt })
  }
  if(newToken) {
    cookies.set('token', newToken, { path: '/', expires: expiresAt })
  }

}

export const logout = async () => {
  try {
    await enhancedFetch('/api/logout', {
      method: 'DELETE',
    })
    
    cookies.remove('token', { path: '/' })
    cookies.remove('user', { path: '/' })
  } catch (e) {
    console.error('Logout error', e)
  }
  return true
}
