import { Suspense, lazy } from 'react'
import { Navigate, RouteObject, useRoutes } from 'react-router-dom'
import { User } from './data'
import LogoOnlyLayout from './layouts/LogoOnlyLayout'
import DashboardLayout from './layouts/dashboard'
import { useSession } from './lib/api/auth/use-session'
import { usePermissions } from './lib/authorization/Can'
import { ADMIN_ROLES, CUSTOMER_ROLES } from './lib/authorization/constants'
import { AppAbility } from './lib/authorization/types'

// Lazy load components
const DocumentTypes = lazy(() => import('./pages/DocumentTypes'))
const DocumentTypeView = lazy(() => import('./pages/DocumentTypes/DocumentTypeView'))
const Customers = lazy(() => import('./pages/Customers/Customers'))
const CustomerView = lazy(() => import('./pages/Customers/CustomerView'))
const DocumentsListPage = lazy(() => import('./pages/Documents/DocumentsListPage'))
const DocumentView = lazy(() => import('./pages/Documents/DocumentView'))
const Members = lazy(() => import('./pages/Members/Members'))
const MemberView = lazy(() => import('./pages/Members/MemberView'))
const Login = lazy(() => import('./pages/Login'))
const ResetPassword = lazy(() => import('./pages/PasswordReset/ResetPassword'))
const NotFound = lazy(() => import('./pages/Page404'))
const Profile = lazy(() => import('./pages/Profile'))
const Groups = lazy(() => import('./pages/Groups'))
const GroupChild = lazy(() => import('./pages/Groups/GroupChild'))
const DocumentGroups = lazy(() => import('./pages/DocumentGroups'))
const DocumentGroupsCreate = lazy(() => import('./pages/DocumentGroups/DocumentGroupsCreate'))
const DocumentGroupsEditPage = lazy(() => import('./pages/DocumentGroups/DocumentGroupsEditPage'))
const Dashboard = lazy(() => import('./pages/Dashboard'))
const Logs = lazy(() => import('./pages/Logs'))
// ... load other components ...

const getDefaultRoute = (role?: User['role']) => {
  if(CUSTOMER_ROLES.includes(role as any)) {
    return '/dashboard'
  }

  if(ADMIN_ROLES.includes(role as any)) {
    return '/dashboard'
  }

  return '/login'
}

const buildDashboardRoutes = (user: User, _ability: AppAbility) => {
  let routes = [
    { path: 'profile', element: <Profile /> },
  ]
  if(CUSTOMER_ROLES.includes(user.role as any)) {
    routes = [
      ...routes,
      { path: '', element: <Dashboard /> },
      { path: 'documents', element: <DocumentsListPage cookie_name="main_document_list" /> },
      { path: 'documents/:id', element: <DocumentView /> },
      { path: 'members', element: <Members /> },
      { path: 'members/:id', element: <MemberView /> },
      { path: 'document-groups', element: <DocumentGroups /> },
      { path: 'document-groups/create', element: <DocumentGroupsCreate /> },
      { path: 'document-groups/:id', element: <DocumentGroupsEditPage /> },
      { path: 'document/:id', element: <Groups /> },
      { path: 'groups', element: <Groups /> },
      { path: 'groups/:id', element: <GroupChild /> },
      { path: 'logs', element: <Logs /> },
    ]
  }

  if(ADMIN_ROLES.includes(user.role as any)) {
    routes = [
      ...routes,
      { path: '', element: <Dashboard /> },
      { path: 'document-types', element: <DocumentTypes /> },
      { path: 'document-types/:id', element: <DocumentTypeView /> },
      { path: 'customers', element: <Customers /> },
      { path: 'customers/:id', element: <CustomerView /> },
      { path: 'members', element: <Members /> },
      { path: 'members/:id', element: <MemberView /> },
    ]
  }

  return routes
}

const Router = () => {
  const { user } = useSession()
  const ability = usePermissions()
  const role = user?.role

  const dashboardRoutes = user ? buildDashboardRoutes(user, ability) : []


  const routes: RouteObject[] = [
    {
      path: '/',
      element: user ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        {
          path: 'dashboard',
          children: dashboardRoutes,
        },
        { path: '/', element: <Navigate to={getDefaultRoute(role)} /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <NotFound /> },
      ],
    },
    {
      path: 'login',
      element: user ? <Navigate to={getDefaultRoute(role)} /> : <Login />,
    },
    {
      path: 'reset_password',
      element: <ResetPassword />,
    },
  ]

  const routesValue = useRoutes(routes)

  return <Suspense fallback={<LogoOnlyLayout />}>{routesValue}</Suspense>
}

export default Router
