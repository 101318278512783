import { LocalizationProvider as DatePickerLocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { enUS } from 'date-fns/locale/en-US'
import { Toaster } from 'react-hot-toast'
import { SWRConfig } from 'swr'
import ScrollToTop from './components/ScrollToTop'
import { fetcher } from './lib/api'
import { AuthProvider } from './lib/api/auth/context'
import { AbilityProvider } from './lib/authorization/Can'
import { processDocument } from './lib/swr-middleware/process-document'
import Router from './routes'
import ThemeProvider from './theme'

export default function App() {
  return (
    <>
      <SWRConfig
        value={{
          fetcher,
          use: [processDocument],
        }}
      >
        <AuthProvider>
          <AbilityProvider>
            <ThemeProvider>
              <ScrollToTop />
              <DatePickerLocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
                <Router />
              </DatePickerLocalizationProvider>
            </ThemeProvider>
          </AbilityProvider>
        </AuthProvider>
      </SWRConfig>
      <Toaster position="bottom-center" />
    </>
  )
}
